import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card, Table, Carousel, Popover, Button, OverlayTrigger } from 'react-bootstrap';
import qrcode from './Assets/images/bantulotto-qr.png';
import lotto from './Assets/images/lotto.jpg';
import lotto1 from './Assets/images/lotto1.jpg';
import blockchain from './Assets/images/blockchain.png';
import lotto2 from './Assets/images/lotto2.jpg';
import { getAllParticipants } from './services';
import { getAllWinners } from './services';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';
import moment from 'moment';

import './App.css';

library.add(faFolderOpen);

function App() {
  const [participants, setParticipants] = useState([]);
  const [winners, setWinners] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const getParticipantsApi = async () => {
      setParticipants(await getAllParticipants());
    };
    setInterval(async () => {
      getParticipantsApi();
    }, 5000);
  }, [setParticipants]);

  useEffect(() => {
    const getWinnersApi = async () => {
      setWinners(await getAllWinners());
    };
    setInterval(async () => {
      getWinnersApi();
    }, 5000);
  }, [setWinners]);

  const length = 10;
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3" style={{fontSize:'18px'}}>Rules of Engagement</Popover.Title>
      <Popover.Content>
        <p><ul>
          <li style={{fontSize:'18px'}}>May sure you always send the correct Amount to bantulotto</li>
          </ul></p>
      </Popover.Content>
    </Popover>
  );

  const totalPayout = () => {
    let t = 0;
    winners?.data?.map(w => {
      t += parseInt(w.amount, 10);
    })
    return t
  }
  


  return (
    <Container fluid>
      <Container>
        <Row>
          <Col>
            <h1 style={ { fontSize: '48px', color: '#ea750d' } }>
              Bantu Lotto
              </h1>
            <Carousel>
              <Carousel.Item>
                <div
                  className="d-block w-100"
                  alt="First slide"
                  className="carousel-1"
                  style={ { background: `url(${ lotto }`, width: '100%', height: '500px', backgroundSize: 'cover', backgroundPosition: 'center' } }
                />
                <Carousel.Caption>
                  <h2 style={ { color: '#ea750d' } }>Lottery</h2>
                  <p style={ { fontSize: '18px' } }>A lottery platform built on the blockchain technology</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="d-block w-100"
                  alt="Third slide"
                  className="carousel-2"
                  style={ { background: `url(${ blockchain }`, width: '100%', height: '500px', backgroundSize: 'cover', backgroundPosition: 'center' } }
                > </div>

                <Carousel.Caption>
                  <h2 style={ { color: '#ea750d' } }>Blockchain</h2>
                  <p style={ { fontSize: '18px' } }>A decentralized, transparent, peer-to-peer, system enabled on Bantu blockchain</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>

            <div className="counter counter--2 bg-primary">
              <div className="container content_above">
                <div className="row">
                  <div className="col">
                    <div className="d-flex justify-content-around flex-wrap">

                      <div className="counter_single">

                        <div className="icon">
                          <span className="la la-folder-open-o"><FontAwesomeIcon icon="faFolderOpen" /></span>
                        </div>

                        <p className="value count_up">{winners?.data?.length || '...'}</p>
                        <p className="title">Cycles</p>
                      </div>

                      <div className="counter_single">

                        <div className="icon">
                          <span className="la la-headphones"></span>
                        </div>

                        <p className="value count_up">10</p>
                        <p className="title">Total Players</p>
                      </div>

                      <div className="counter_single">

                        <div className="icon">
                          <span className="la la-trophy"></span>
                        </div>

                        <p className="value count_up">{totalPayout() || '...'} XBN</p>
                        <p className="title">Payouts To Winners</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
          <Row>
            <Col xs={ 12 } md={ 7 }>
              <Card body>
                <div style={ { textAlign: 'center' } }>
                  <img src={ qrcode } alt="QR Code" style={ { width: '300px', height: 'auto' } } />
                  <h3 style={ { textAlign: 'center', margin: '10px' } }>bantulotto</h3>
                  <h4 style={ { color: '#ea750d', margin: '10px', background: '#ffffff', padding: '10px 0' } }> Send 100 XBN to Play</h4>
                  <OverlayTrigger trigger="click" placement="top" overlay={ popover }>
                    <Button style={ { color: '#fff', margin: '10px', background: '#ea750d', border: '#ea750d' } }>See Rules</Button>
                  </OverlayTrigger>
                </div>
              </Card>
              <Card body ><h3>
                Participants
                </h3>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th>Public Key</th>
                    </tr>
                  </thead>
                  { !participants?.data ? <Row style={ { justifyContent: 'center' } }><Loader type="ThreeDots"
          color="#ea750d"
          height={ 40 }
          width={ 40 } /></Row> :
                  <tbody>
                    { participants?.data?.map((item, index) => {
                      return (
                        <tr key={ index }>
                          <td>{ item.publicKey }</td>
                        </tr>
                      );
                    }) }
                  </tbody>
}
                </Table>
              </Card>
              </Col>
            <Col xs={ 12 } md={ 5 }><Card body>
              <h3>
                Recent Winners
    </h3>
              <Table hover size="sm">
                <thead>
                  <tr>
                    <th>Public Key</th>
                    <th>Amount</th>
                    <th>Time</th>
                  </tr>
                </thead>
                { !winners?.data ? <Row style={ { justifyContent: 'center' } }><Loader type="ThreeDots"
          color="#ea750d"
          height={ 40 }
          width={ 40 } /></Row> :
                <tbody>
                  { winners?.data?.map((item, index) => {
                    return (
                      <tr key={ index }>
                        <td>{ item.publicKey.substring(0, length) + '...' }</td>
                        <td>{`${item.amount} XBN`}</td>
                        <td>{moment(item.timeStamp).fromNow()}</td>
                      </tr>
                    );
                  }) }
                </tbody>
}
              </Table>
            </Card>
            </Col>
          </Row>
        <div className="counter counter--2 bg-primary mt-5">
          <div class="footer__small text-center pt-5 pb-3 footer-s ">
            <p>©2021 Lotto. All rights reserved.</p>
          </div>
        </div>
      </Container>
    </Container>
  );
}

export default App;
