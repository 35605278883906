import axios from 'axios';

export const getAllParticipants = async () => await axios.get('https://bantulotto-backend.dev.bantu.network/api/v1/participants')
.then(function (response) {
  return response.data
})
.catch(function (error) {
  console.log(error);
});

export const getAllWinners = async () => await axios.get('https://bantulotto-backend.dev.bantu.network/api/v1/winners')
.then(function (response) {
  return response.data
})
.catch(function (error) {
  console.log(error);
});

